<i18n>
  {
    "es": {
      "EMPTY_CART": "Tu cesta está vacía",
      "ADD_ITEMS": "Añade productos a tu carrito para comenzar tu compra",
      "EXPLORE_PRODUCTS": "Explorar productos"
    },
    "en": {
      "EMPTY_CART": "Your cart is empty",
      "ADD_ITEMS": "Add products to your cart to start your purchase",
      "EXPLORE_PRODUCTS": "Explore products"
    },
    "fr": {
      "EMPTY_CART": "Votre panier est vide",
      "ADD_ITEMS": "Ajoutez des produits à votre panier pour commencer votre achat",
      "EXPLORE_PRODUCTS": "Explorer les produits"
    },
    "de": {
      "EMPTY_CART": "Ihr Warenkorb ist leer",
      "ADD_ITEMS": "Fügen Sie Produkte zu Ihrem Warenkorb hinzu, um Ihren Einkauf zu beginnen",
      "EXPLORE_PRODUCTS": "Produkte erkunden"
    },
    "it": {
      "EMPTY_CART": "Il tuo carrello è vuoto",
      "ADD_ITEMS": "Aggiungi prodotti al carrello per iniziare il tuo acquisto",
      "EXPLORE_PRODUCTS": "Esplora i prodotti"
    },
    "pt": {
      "EMPTY_CART": "Seu carrinho está vazio",
      "ADD_ITEMS": "Adicione produtos ao carrinho para iniciar sua compra",
      "EXPLORE_PRODUCTS": "Explorar produtos"
    }
  }
</i18n>

<template>
  <div class="py-4 px-6">
    <div class="flex flex-col items-center justify-center mt-12">
      <img
        aria-label="shopping bag"
        src="@/assets/svg/icons/grey/icons-shoppingbag.svg"
        alt="shopping bag"
        class="w-12 h-12"
      />

      <h2
        class="mt-8 text-xl md:text-2xl text-gray-600 text-center tracking-wider"
      >
        {{ t('EMPTY_CART') }}
      </h2>

      <p class="mt-4 text-base text-gray-500 mb-8 text-center max-w-md">
        {{ t('ADD_ITEMS') }}
      </p>

      <button
        @click="emit('redirect')"
        class="flex justify-center mx-auto max-w-[14rem] bg-black text-white px-8 py-3 rounded text-xs uppercase font-bold tracking-[1px] bg-opacity-90 w-full mdplus:mx-auto hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s] transition-all duration-200 hover:cursor-pointer"
        style="background-size: 200% 100%"
      >
        {{ t('EXPLORE_PRODUCTS') }}
      </button>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['redirect'])
</script>
