export const useCountrySelectorState = () => {
  return useState<boolean>('countrySelectorState', () => false)
}

export const useChallengeState = () => {
  return useState<boolean>('challengeState', () => false)
}

export default function useCountrySelector() {
  const countrySelector = useCountrySelectorState()
  const challenge = useChallengeState()

  const triggerChallenge = () => {
    challenge.value = true
    countrySelector.value = true
  }

  const show = () => {
    countrySelector.value = true
  }

  const hide = () => {
    countrySelector.value = false
    challenge.value = false
  }

  return {
    challenge: readonly(challenge),
    countrySelector: readonly(countrySelector),
    show,
    hide,
    triggerChallenge,
  }
}
