<i18n>
  {
    "es": {
      "CART": "Mi cesta",
      "ITEMS": "articulos",
      "WE_ACCEPT": "Aceptamos",
      "TOTAL": "Total",
      "IVA": "IVA incluido",
      "COMPLETE_ORDER": "Continuar al pago",
      "COMPLETING_ORDER": "Procediendo al pago",
      "ERROR": "Upsss, algo ha fallado. Vuelva a intentarlo mas tarde",
      "FAVOURITE_ADDED": "Producto añadido a favoritos correctamente",
      "MUST_BE_LOGGED": "Debes iniciar sesión para realizar esta acción",
      "LOGIN": "Iniciar sesión",
    },
    "en": {
      "CART": "My cart",
      "ITEMS": "items",
      "WE_ACCEPT": "We accept",
      "TOTAL": "Total",
      "IVA": "VAT included",
      "COMPLETE_ORDER": "Proceed to payment",
      "COMPLETING_ORDER": "Proceeding to payment",
      "ERROR": "Oops, something went wrong. Please try again later",
      "FAVOURITE_ADDED": "Product added to favourites successfully",
      "LOGIN": "Log in"
    },
    "fr": {
      "CART": "Mon panier",
      "ITEMS": "articles",
      "WE_ACCEPT": "Nous acceptons",
      "TOTAL": "Total",
      "IVA": "TVA incluse",
      "COMPLETE_ORDER": "Procéder au paiement",
      "COMPLETING_ORDER": "Procédant au paiement",
      "ERROR": "Oups, quelque chose s'est mal passé. Veuillez réessayer plus tard",
      "FAVOURITE_ADDED": "Produit ajouté aux favoris avec succès",
      "LOGIN": "Se connecter"
    },
    "de": {
      "CART": "Mein Warenkorb",
      "ITEMS": "Artikel",
      "WE_ACCEPT": "Wir akzeptieren",
      "TOTAL": "Gesamt",
      "IVA": "MwSt. enthalten",
      "COMPLETE_ORDER": "Zur Zahlung gehen",
      "COMPLETING_ORDER": "Zur Zahlung",
      "ERROR": "Hoppla, etwas ist schief gelaufen. Bitte versuchen Sie es später erneut",
      "FAVOURITE_ADDED": "Produkt erfolgreich zu Favoriten hinzugefügt",
      "LOGIN": "Einloggen"
    },
    "it": {
      "CART": "Il mio carrello",
      "ITEMS": "articoli",
      "WE_ACCEPT": "Accettiamo",
      "TOTAL": "Totale",
      "IVA": "IVA inclusa",
      "COMPLETE_ORDER": "Procedi al pagamento",
      "COMPLETING_ORDER": "Procedendo al pagamento",
      "ERROR": "Ops, qualcosa è andato storto. Riprova più tardi",
      "FAVOURITE_ADDED": "Prodotto aggiunto ai preferiti con successo",
      "LOGIN": "Accedi"
    },
    "pt": {
      "CART": "Meu carrinho",
      "ITEMS": "itens",
      "WE_ACCEPT": "Aceitamos",
      "TOTAL": "Total",
      "IVA": "IVA incluído",
      "COMPLETE_ORDER": "Proceder ao pagamento",
      "COMPLETING_ORDER": "Procedendo ao pagamento",
      "ERROR": "Ops, algo deu errado. Por favor, tente novamente mais tarde",
      "FAVOURITE_ADDED": "Produto adicionado aos favoritos com sucesso",
      "LOGIN": "Entrar"
    }
  }
</i18n>

<template>
  <div>
    <div v-if="loading" class="flex items-center mt-1 p-4 justify-between">
      <div class="w-32 h-4 rounded-lg bg-[#d8d8d8]"></div>
      <div class="w-4 h-4 rounded-lg bg-[#d8d8d8]"></div>
    </div>

    <div v-else class="flex items-center p-4 justify-between">
      <span class="text-xl font-semibold tracking-wide">
        {{ t('CART') }} <span>({{ cartInfo?.cart.count ?? 0 }})</span>
      </span>
      <button @click="toggle" type="button">
        <img src="@/assets/svg/icons/icons-close.svg" class="h-6 w-6" />
      </button>
    </div>

    <transition name="fade">
      <div>
        <div v-if="loading">
          <div class="h-full pb-80 md:pb-0">
            <div
              class="h-[calc(100vh-13rem)] md:max-h-[580px] xl:max-h-[580px] overflow-y-auto"
            >
              <div id="cart-items" class="mt-2 pb-20">
                <CartItem
                  v-for="(item, index) in 2"
                  :key="item.id"
                  :item="item"
                  :loading="loading"
                  :isLastItem="index === 1"
                />
              </div>
              <div v-if="isMobileOrTablet" class="flex flex-col w-full">
                <div class="mb-4 mx-4 h-px bg-[#d8d8d8]"></div>

                <CartCoupons
                  :coupons="cartInfo?.coupons"
                  :loading="loading"
                  class="pt-4"
                />
                <div id="cart-totals" class="mb-20 text-sm font-light">
                  <CartTotals
                    :totals="cartInfo?.cart.totals"
                    :currency="cartInfo?.currency"
                    :loading="loading"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="!isMobileOrTablet"
              class="flex fixed justify-end bottom-32 pb-2 md:flex-col w-full bg-white"
            >
              <div class="mb-4 mx-4 h-px bg-[#d8d8d8]"></div>
              <CartCoupons :coupons="cartInfo?.coupons" :loading="loading" />

              <div id="cart-totals" class="mb-5 text-sm font-light">
                <CartTotals
                  :totals="cartInfo?.cart.totals"
                  :currency="cartInfo?.currency"
                  :loading="loading"
                />
              </div>
            </div>
          </div>
          <div
            class="fixed bottom-0 w-full bg-white pb-24 md:pb-12 px-4 pt-4 shadow-inner mdplus:rounded-lg z-20"
          >
            <div class="flex justify-between text-sm py-2 mb-2">
              <div class="w-28 h-4 bg-[#d8d8d8] rounded-lg"></div>

              <div class="w-14 h-4 bg-[#d8d8d8] rounded-lg"></div>
            </div>

            <div class="w-full h-14 bg-[#d8d8d8] rounded-lg"></div>
          </div>
        </div>

        <div v-else>
          <div v-if="cartInfo?.cart.items.length == 0">
            <CartEmpty @redirect="handleRedirect" />
          </div>
          <div v-if="cartInfo?.cart.items.length > 0">
            <div class="h-full pb-80 md:pb-0">
              <div
                class="h-[calc(100vh-13rem)] md:h-[calc(100vh-10rem)] xl:h-[calc(100vh-28rem)] overflow-y-auto"
              >
                <CartPromotionsSlider :promotions="cartInfo?.cart.promotions" />
                <div id="cart-items" class="pb-20">
                  <CartItem
                    v-for="(item, index) in cartInfo?.cart.items"
                    :key="item.id"
                    :item="item"
                    :currency="cartInfo?.currency"
                    :isLastItem="index === cartInfo?.cart.items.length - 1"
                    @update="handleUpdate($event, item.attribute_id)"
                    @remove="handleRemove($event, item.attribute_id)"
                    @favourite="handleFavourite(item.product_id)"
                  />
                </div>
                <div v-if="isMobileOrTablet" class="flex flex-col w-full">
                  <div class="mb-4 mx-4 h-px bg-others-black"></div>

                  <CartCoupons
                    :coupons="cartInfo?.cart?.coupons"
                    @add="addCoupon($event)"
                    @remove="removeCoupon($event)"
                  />
                  <div id="cart-totals" class="mb-20 text-sm font-light">
                    <CartTotals
                      :totals="cartInfo?.cart.totals"
                      :currency="cartInfo?.currency"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="!isMobileOrTablet"
                class="flex fixed justify-end bottom-32 pb-2 md:flex-col w-full bg-white"
              >
                <div class="mb-4 mx-4 h-px bg-others-black"></div>

                <CartCoupons
                  :coupons="cartInfo?.cart?.coupons"
                  @add="addCoupon($event)"
                  @remove="removeCoupon($event)"
                />
                <div id="cart-totals" class="mb-5 text-sm font-light">
                  <CartTotals
                    :totals="cartInfo?.cart.totals"
                    :currency="cartInfo?.currency"
                  />
                </div>
              </div>
            </div>
            <div
              class="fixed bottom-0 w-full bg-white pb-24 md:pb-12 px-4 pt-4 shadow-inner mdplus:rounded-lg z-20"
            >
              <div class="flex justify-between text-sm py-2 mb-2">
                <p class="font-bold tracking-wider">
                  {{ t('TOTAL') }}
                  <span class="text-others-grey3 font-thin tracking-wider">{{
                    t('IVA')
                  }}</span>
                </p>

                <p class="font-semibold tracking-wider">
                  {{ formatPrice(total) }} {{ cartInfo?.currency.symbol }}
                </p>
              </div>

              <button
                @click="handleConfirm()"
                type="button"
                class="h-14 w-full tracking-wider rounded uppercase bg-others-black font-bold text-sm text-white hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s] transition-all duration-200 hover:cursor-pointer flex items-center justify-center"
                :style="'background-size: 200% 100%;'"
              >
                <img
                  v-if="isLoading"
                  src="@/assets/svg/icons/icons-loading.svg"
                  alt="icono o"
                  class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                />

                <span v-if="isLoading">{{ t('COMPLETING_ORDER') }}</span>
                <span v-else>{{ t('COMPLETE_ORDER') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { formatPrice } from '@/utils/helpers'

const router = useRouter()
const { t, locale } = useI18n({
  useScope: 'local',
})

const { sendViewCart, sendAddToCart, sendRemoveToCart, sendBegingCheckout } =
  useGoogleAnalyticsEvent()

const { addMessage } = useSnackBar()
const { setTotalItems, hide: hideCart } = useCart()
const { isMobileOrTablet } = useDevice()
const config = useRuntimeConfig()

const total = ref(0)
const isLoading = ref(false)

const {
  data: cartInfo,
  pending: loading,
  error,
  refresh,
} = useLazyAsyncData(() =>
  $fetch(`/api/cart`, {
    method: 'POST',
  })
)

// TODO REFACTOR, QUITAR LA LLAMADA AL localStorage y usar el composable
onActivated(() => {
  //  Si es la primera vez que abre la cesta hago return ya que el cartInfo va a estar vacio
  //  y como se lo tiene que traer no hace falta lo demas
  if (!cartInfo.value) {
    return
  }
  const localItems = parseInt(localStorage.getItem('cartTotalItems'))
  if (localItems != cartInfo.value.cart.count) {
    refresh()
  }
  // TODO: LLLAMO SIEMPRE QUE SE ABRA LA CESTA?
  // const { sendViewCart } = useGoogleAnalyticsEvent()
  // sendViewCart(cartInfo.value.cart, cartInfo.value.currency)
})

const { sendCart } = useConnectif()

watch(cartInfo, () => {
  setTotalItems(cartInfo.value.cart.count)

  total.value = cartInfo.value.cart.totals.find((t) => t.type === 'total').value
  loading.value = false

  if (cartInfo.value.message) {
    addMessage({
      type: cartInfo.value.message.type,
      result: cartInfo.value.message.title,
      text: cartInfo.value.message.text,
    })
  }

  sendCart(locale.value, cartInfo.value.cart)
  sendViewCart(cartInfo.value.cart, cartInfo.value.currency)
})

if (error.value) {
  handleError(error.value)
}

const emit = defineEmits(['close'])

const toggle = () => {
  emit('close')
}

const handleUpdate = async (qty, attributeId) => {
  loading.value = true
  const { data, error } = await useAsyncData('updateItemCart', () =>
    $fetch(`/api/cart/items/update`, {
      method: 'POST',
      body: {
        attributeId: attributeId,
        qty: qty,
      },
    })
  )
  if (error.value) {
    handleError(error.value)
  } else {
    let isAdd = true
    const item = cartInfo.value.cart.items.find(
      (item) => item.attribute_id === attributeId
    )
    if (item.qty > qty) {
      isAdd = false
    }
    cartInfo.value = data.value

    if (isAdd) {
      // si es añadir a la cesta
      sendAddToCart(cartInfo.value.cart, cartInfo.value.currency)
    } else {
      // si es borrar de la cesta y todavia quedan items
      sendRemoveToCart(cartInfo.value.cart, cartInfo.value.currency)
    }
  }

  loading.value = false
}

const handleRemove = async (event, attributeId) => {
  const qty = Number(event)
  loading.value = true
  const { data, error } = await useAsyncData('removeItemToCart', () =>
    $fetch(`/api/cart/items/remove`, {
      method: 'POST',
      body: {
        attributeId: attributeId,
        qty: qty,
      },
    })
  )
  if (error.value) {
    handleError(error.value)
  } else {
    cartInfo.value = data.value

    sendRemoveToCart(cartInfo.value.cart, cartInfo.value.currency)
  }

  loading.value = false
}

const isLogged = async () => {
  const { isLoggedIn } = useAuthUser()
  return import.meta.client ? await isLoggedIn() : false
}

const handleFavourite = async (productId) => {
  const logged = await isLogged()
  if (!logged) {
    router.push(`/${locale.value}/login`)
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('MUST_BE_LOGGED'),
    })
    return
  }
  loading.value = true
  const { data, error } = await useAsyncData('addItemToFavourites', () =>
    $fetch(`/api/account/favourites/add`, {
      method: 'POST',
      body: {
        productId: productId,
      },
    })
  )
  if (error.value) {
    handleError(error.value)
  } else {
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('FAVOURITE_ADDED'),
    })
  }

  loading.value = false
}

const addCoupon = async (coupon) => {
  loading.value = true
  const { data, error } = await useAsyncData('addCoupon', () =>
    $fetch('/api/cart/coupons/add', {
      method: 'POST',
      body: {
        coupons: [coupon],
      },
    })
  )

  if (error.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR_ADDING_COUPON'),
    })
    loading.value = false
    return
  }

  cartInfo.value = data.value
  loading.value = false
}

const removeCoupon = async (selectedCoupon) => {
  loading.value = true
  const { data, error } = await useAsyncData('addCoupon', () =>
    $fetch('/api/cart/coupons/remove', {
      method: 'POST',
      body: {
        code: selectedCoupon.code,
      },
    })
  )

  if (error.value) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('ERROR_ADDING_COUPON'),
    })
    loading.value = false
    return
  }

  cartInfo.value = data.value
  loading.value = false
}

const handleConfirm = async () => {
  const logged = await isLogged()
  if (!logged) {
    hideCart()
    router.push(`/${locale.value}/login`)
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('MUST_BE_LOGGED'),
    })
    return
  }

  isLoading.value = true
  const { data: checkout, error } = await useAsyncData('checkoutInit', () =>
    $fetch('/api/checkout/init')
  )
  sendBegingCheckout(checkout.value)
  hideCart()
  router.push({
    path: `/${locale.value}/checkout/${checkout.value.id}`,
  })
  isLoading.value = false
}

const handleError = (error) => {
  console.debug(error)
  loading.value = false
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: t('ERROR'),
  })
}

const handleRedirect = () => {
  toggle()
  router.push(`/${locale.value}`)
}
</script>
