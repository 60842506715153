<template>
  <div v-if="nuxtLoader" class="fixed top-0 left-0 w-full h-full z-9999">
    <div class="flex justify-center items-center w-full h-full relative z-9999">
      <div class="w-28 h-28 relative scale-75 md:scale-100">
        <div
          class="top-0 left-0 w-[59px] h-[59px] absolute border-[6px] border-white rounded-full scale-100 animate-[pulse-black_1s_infinite]"
        ></div>
        <div
          class="top-0 right-0 w-[59px] h-[59px] absolute border-[6px] border-white rounded-full scale-100 animate-[pulse-black_1s_infinite]"
        ></div>
        <div
          class="bottom-0 left-0 w-[59px] h-[59px] absolute border-[6px] border-white rounded-full scale-100 animate-[pulse-black_1s_infinite]"
        ></div>
        <div
          class="bottom-0 left-0 right-0 top-0 m-auto w-[59px] h-[59px] absolute border-[6px] border-white rounded-full scale-100 animate-[pulse-black_1s_infinite]"
        ></div>
        <div
          class="bottom-0 right-0 w-[59px] h-[59px] absolute border-[6px] border-white rounded-full scale-100 animate-[pulse-black_1s_infinite]"
        ></div>
      </div>
    </div>
    <div
      class="absolute w-full h-full top-0 left-0 bg-others-black2 bg-opacity-40 z-[9998]"
    ></div>
  </div>
</template>

<script setup>
const { nuxtLoader } = useLoading()

watch(nuxtLoader, (newValue, oldValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'auto'
  }
})
</script>
