<i18n>
  {
    "es": {
      "FAST_DELIVERY": "Entrega rápida",
      "DELIVERY_DAYS": "en 1 - 3 días laborables",
      "FREE_SHIPPING": "Envío gratuito",
      "FROM_20": "A partir de 25€*",
      "SECURITY_PRIVACY": "Seguridad y privacidad",
      "ALL_PAYMENTS": "en todos tus pagos",
      "DAYS_RETURN": "30 días",
      "RETURN": "de devolución",
      "HELP": "Ayuda",
      "SECURE_PAYMENT": "Pago seguro",
      "SHIPPING_COSTS": "Gastos de envío",
      "DELIVERY_DATE": "Plazos de entrega",
      "RETURNS": "Devoluciones",
      "INFO": "Información",
      "GENERAL_CONDITIONS": "Condiciones generales",
      "DATA_PROTECTION": "Protección de datos",
      "COOKIE_POLICY": "Uso de cookies",
      "CLAIMS": "Reclamaciones y litigios",
      "COMPANY": "Empresa",
      "KNOW_US": "Conócenos",
      "CONTACT_US": "Contacta con nosotros",
      "WORK_WITH_US": "Trabaja en San Remo",
      "PHYSICAL_STORES": "Tiendas físicas",
      "WHERE_ARE_WE": "¿Dónde estamos?",
      "ADDRESS": "C/ Ávila, 121, 123.",
      "ADDRESS_2": "08018, Barcelona (España)",
      "PHONE": "911 670 052",
      "TIMETABLE": "L-V de 9h a 18h, excepto festivos.",
      "COPYRIGHT": "© 2024 Perfumerías San Remo S.A. - Registro Sanitario: 26.09151/CAT",
      "CLUB": "Club San Remo",
    },
    "en": {
      "FAST_DELIVERY": "Fast delivery",
      "DELIVERY_DAYS": "in 1 - 3 business days",
      "FREE_SHIPPING": "Free shipping",
      "FROM_20": "From €25*",
      "SECURITY_PRIVACY": "Security and privacy",
      "ALL_PAYMENTS": "on all your payments",
      "DAYS_RETURN": "30 days",
      "RETURN": "return policy",
      "HELP": "Help",
      "SECURE_PAYMENT": "Secure payment",
      "SHIPPING_COSTS": "Shipping costs",
      "DELIVERY_DATE": "Delivery times",
      "RETURNS": "Returns",
      "INFO": "Information",
      "GENERAL_CONDITIONS": "General conditions",
      "DATA_PROTECTION": "Data protection",
      "COOKIE_POLICY": "Cookie policy",
      "CLAIMS": "Claims and disputes",
      "COMPANY": "Company",
      "KNOW_US": "Get to know us",
      "CONTACT_US": "Contact us",
      "WORK_WITH_US": "Work at San Remo",
      "PHYSICAL_STORES": "Physical stores",
      "WHERE_ARE_WE": "Where are we?",
      "ADDRESS": "C/ Ávila, 121, 123.",
      "ADDRESS_2": "08018, Barcelona (Spain)",
      "PHONE": "911 670 052",
      "TIMETABLE": "Mon-Fri from 9 AM to 6 PM, except holidays.",
      "COPYRIGHT": "© 2024 Perfumerías San Remo S.A. - Health Registration: 26.09151/CAT",
      "CLUB": "San Remo Club",
    },
    "fr": {
      "FAST_DELIVERY": "Livraison rapide",
      "DELIVERY_DAYS": "en 1 - 3 jours ouvrés",
      "FREE_SHIPPING": "Livraison gratuite",
      "FROM_20": "À partir de 25€*",
      "SECURITY_PRIVACY": "Sécurité et confidentialité",
      "ALL_PAYMENTS": "sur tous vos paiements",
      "DAYS_RETURN": "30 jours",
      "RETURN": "de retour",
      "HELP": "Aide",
      "SECURE_PAYMENT": "Paiement sécurisé",
      "SHIPPING_COSTS": "Frais de livraison",
      "DELIVERY_DATE": "Délais de livraison",
      "RETURNS": "Retours",
      "INFO": "Informations",
      "GENERAL_CONDITIONS": "Conditions générales",
      "DATA_PROTECTION": "Protection des données",
      "COOKIE_POLICY": "Politique de cookies",
      "CLAIMS": "Réclamations et litiges",
      "COMPANY": "Entreprise",
      "KNOW_US": "Faites connaissance avec nous",
      "CONTACT_US": "Nous contacter",
      "WORK_WITH_US": "Travailler chez San Remo",
      "PHYSICAL_STORES": "Magasins physiques",
      "WHERE_ARE_WE": "Où sommes-nous?",
      "ADDRESS": "C/ Ávila, 121, 123.",
      "ADDRESS_2": "08018, Barcelone (Espagne)",
      "PHONE": "911 670 052",
      "TIMETABLE": "L-V de 9h à 18h, sauf jours fériés.",
      "COPYRIGHT": "© 2024 Perfumerías San Remo S.A. - Enregistrement sanitaire : 26.09151/CAT",
      "CLUB": "Club San Remo",
    },
    "de": {
      "FAST_DELIVERY": "Schnelle Lieferung",
      "DELIVERY_DAYS": "in 1 - 3 Werktagen",
      "FREE_SHIPPING": "Kostenloser Versand",
      "FROM_20": "Ab 25€*",
      "SECURITY_PRIVACY": "Sicherheit und Datenschutz",
      "ALL_PAYMENTS": "für alle Zahlungen",
      "DAYS_RETURN": "30 Tage",
      "RETURN": "Rückgaberecht",
      "HELP": "Hilfe",
      "SECURE_PAYMENT": "Sichere Zahlung",
      "SHIPPING_COSTS": "Versandkosten",
      "DELIVERY_DATE": "Lieferzeiten",
      "RETURNS": "Rücksendungen",
      "INFO": "Informationen",
      "GENERAL_CONDITIONS": "Allgemeine Bedingungen",
      "DATA_PROTECTION": "Datenschutz",
      "COOKIE_POLICY": "Cookie-Richtlinie",
      "CLAIMS": "Reklamationen und Streitigkeiten",
      "COMPANY": "Unternehmen",
      "KNOW_US": "Lernen Sie uns kennen",
      "CONTACT_US": "Kontaktieren Sie uns",
      "WORK_WITH_US": "Arbeiten bei San Remo",
      "PHYSICAL_STORES": "Physische Geschäfte",
      "WHERE_ARE_WE": "Wo sind wir?",
      "ADDRESS": "C/ Ávila, 121, 123.",
      "ADDRESS_2": "08018, Barcelona (Spanien)",
      "PHONE": "911 670 052",
      "TIMETABLE": "Mo-Fr von 9 bis 18 Uhr, außer an Feiertagen.",
      "COPYRIGHT": "© 2024 Perfumerías San Remo S.A. - Gesundheitsregistrierung: 26.09151/CAT",
      "CLUB": "San Remo Club",
    },
    "it": {
      "FAST_DELIVERY": "Consegna veloce",
      "DELIVERY_DAYS": "in 1 - 3 giorni lavorativi",
      "FREE_SHIPPING": "Spedizione gratuita",
      "FROM_20": "Da 25€*",
      "SECURITY_PRIVACY": "Sicurezza e privacy",
      "ALL_PAYMENTS": "su tutti i tuoi pagamenti",
      "DAYS_RETURN": "30 giorni",
      "RETURN": "di restituzione",
      "HELP": "Aiuto",
      "SECURE_PAYMENT": "Pagamento sicuro",
      "SHIPPING_COSTS": "Costi di spedizione",
      "DELIVERY_DATE": "Tempi di consegna",
      "RETURNS": "Resi",
      "INFO": "Informazioni",
      "GENERAL_CONDITIONS": "Condizioni generali",
      "DATA_PROTECTION": "Protezione dei dati",
      "COOKIE_POLICY": "Politica sui cookie",
      "CLAIMS": "Reclami e controversie",
      "COMPANY": "Azienda",
      "KNOW_US": "Conoscici",
      "CONTACT_US": "Contattaci",
      "WORK_WITH_US": "Lavora da San Remo",
      "PHYSICAL_STORES": "Negozi fisici",
      "WHERE_ARE_WE": "Dove siamo?",
      "ADDRESS": "C/ Ávila, 121, 123.",
      "ADDRESS_2": "08018, Barcellona (Spagna)",
      "PHONE": "911 670 052",
      "TIMETABLE": "L-V dalle 9 alle 18, eccetto i festivi.",
      "COPYRIGHT": "© 2024 Perfumerías San Remo S.A. - Registrazione sanitaria: 26.09151/CAT",
      "CLUB": "Club San Remo",
    },
    "pt": {
      "FAST_DELIVERY": "Entrega rápida",
      "DELIVERY_DAYS": "em 1 - 3 dias úteis",
      "FREE_SHIPPING": "Envio grátis",
      "FROM_20": "A partir de 25€*",
      "SECURITY_PRIVACY": "Segurança e privacidade",
      "ALL_PAYMENTS": "em todos os seus pagamentos",
      "DAYS_RETURN": "30 dias",
      "RETURN": "devolução",
      "HELP": "Ajuda",
      "SECURE_PAYMENT": "Pagamento seguro",
      "SHIPPING_COSTS": "Custos de envio",
      "DELIVERY_DATE": "Prazos de entrega",
      "RETURNS": "Devoluções",
      "INFO": "Informações",
      "GENERAL_CONDITIONS": "Condições gerais",
      "DATA_PROTECTION": "Proteção de dados",
      "COOKIE_POLICY": "Política de cookies",
      "CLAIMS": "Reclamações e litígios",
      "COMPANY": "Empresa",
      "KNOW_US": "Conheça-nos",
      "CONTACT_US": "Fale conosco",
      "WORK_WITH_US": "Trabalhe na San Remo",
      "PHYSICAL_STORES": "Lojas físicas",
      "WHERE_ARE_WE": "Onde estamos?",
      "ADDRESS": "C/ Ávila, 121, 123.",
      "ADDRESS_2": "08018, Barcelona (Espanha)",
      "PHONE": "911 670 052",
      "TIMETABLE": "Seg-Sex das 9h às 18h, exceto feriados.",
      "COPYRIGHT": "© 2024 Perfumerías San Remo S.A. - Registro Sanitário: 26.09151/CAT",
      "CLUB": "Clube San Remo",
    }
}
</i18n>

<template>
  <footer>
    <div class="mt-24 w-full bg-others-grey flex flex-col items-start px-4">
      <div class="max-w-1400 mx-auto w-full px-4 lg:px-6">
        <div
          class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-10 py-11"
        >
          <div class="flex flex-col items-center justify-center gap-1">
            <img
              src="@/assets/svg/footer/delivery.svg"
              width="30"
              height="30"
              alt="Camión"
              class="w-8 h-8 mdplus:w-10 mdplus:h-10"
            />
            <p class="text-xs mdplus:text-base font-light tracking-wide">
              {{ t('FAST_DELIVERY') }}
            </p>
            <p class="text-xxs mdplus:text-xs font-normal tracking-wide">
              {{ t('DELIVERY_DAYS') }}
            </p>
          </div>
          <div class="flex flex-col items-center justify-center gap-1">
            <img
              src="@/assets/svg/footer/shipping.svg"
              width="30"
              height="30"
              alt="Caja"
              class="w-8 h-8 mdplus:w-10 mdplus:h-10"
            />

            <p class="text-xs mdplus:text-base font-light tracking-wide">
              {{ t('FREE_SHIPPING') }}
            </p>
            <p class="text-xxs mdplus:text-xs font-normal tracking-wide">
              {{ t('FROM_20') }}
            </p>
          </div>
          <div class="flex flex-col items-center justify-center gap-1">
            <img
              src="@/assets/img/icon_secure.png"
              alt="Regalo"
              class="w-8 h-8 mdplus:w-10 mdplus:h-10"
            />

            <div
              class="text-xs mdplus:text-base font-light leading-5 tracking-wide text-center"
            >
              <span class="block">{{ t('SECURITY_PRIVACY') }}</span>
              <span
                class="block text-xxs mdplus:text-xs font-normal tracking-wide"
                >{{ t('ALL_PAYMENTS') }}</span
              >
            </div>
          </div>
          <div class="flex flex-col items-center justify-center gap-1">
            <img
              src="@/assets/svg/footer/refund.svg"
              width="30"
              height="30"
              alt="Devolución"
              class="w-8 h-8 mdplus:w-10 mdplus:h-10"
            />

            <div
              class="text-xs mdplus:text-base font-light leading-5 tracking-wide text-center"
            >
              <span class="block">{{ t('DAYS_RETURN') }}</span>
              <span class="block">{{ t('RETURN') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer" class="w-full flex flex-col items-start px-4 relative">
      <CustomPicture
        class="w-full h-full absolute inset-0 object-cover object-center z-0 md:min-h-[600px]"
        :mobileImage="'images/footer/mobile-footer.webp'"
        :desktopImage="'images/footer/desktop-footer.webp'"
        :preload="false"
        :loading="'lazy'"
        :autoHeight="false"
      />
      <div
        class="absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-70 md:bg-opacity-60 lg:bg-opacity-0 md:bg-gradient-to-t md:from-black md:to-transparent z-5"
      ></div>

      <div
        class="max-w-1400 mx-auto w-full flex flex-col justify-end min-h-[600px] relative z-10"
      >
        <div
          class="mt-12 grid grid-rows-3 md:grid-rows-2 lg:grid-rows-1 grid-flow-col gap-x-2 md:gap-x-32 xl:gap-x-64 text-white justify-center"
        >
          <div class="space-y-4 md:space-y-6 order-1 lg:order-1">
            <h4 class="mb-2 font-extrabold tracking-wide md:text-xl">
              {{ t('HELP') }}
            </h4>
            <div
              class="flex flex-col gap-y-2 md:gap-y-4 text-xs md:text-xsplus"
            >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/16585178272914-Formas-de-pago"
                target="_blank"
                class="text-xs md:text-xsplus text-white"
                >{{ t('SECURE_PAYMENT') }}</NuxtLink
              >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/16585172049426-Gastos-de-env%C3%ADo"
                target="_blank"
                class="text-xs md:text-xsplus text-white"
                >{{ t('SHIPPING_COSTS') }}</NuxtLink
              >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/16585163913490-Plazos-de-entrega"
                target="_blank"
                class="text-xs md:text-xsplus text-white whitespace-nowrap"
                >{{ t('DELIVERY_DATE') }}</NuxtLink
              >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/16602269396114-Devoluci%C3%B3n"
                target="_blank"
                class="text-xs md:text-xsplus text-white"
                >{{ t('RETURNS') }}</NuxtLink
              >
            </div>
          </div>

          <div class="mt-6 lg:mt-0 space-y-4 md:space-y-6 order-2 lg:order-2">
            <h4 class="mb-2 font-extrabold tracking-wide md:text-xl">
              {{ t('INFO') }}
            </h4>
            <div
              class="flex flex-col gap-y-2 md:gap-y-4 text-xs md:text-xsplus"
            >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/18343748228370-Condiciones-generales"
                target="_blank"
                class="text-xs md:text-xsplus text-white"
                >{{ t('GENERAL_CONDITIONS') }}</NuxtLink
              >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/18549303751570-Pol%C3%ADtica-de-privacidad"
                target="_blank"
                class="text-xs md:text-xsplus text-white"
                >{{ t('DATA_PROTECTION') }}</NuxtLink
              >
              <NuxtLink
                :to="`/${locale}/legal/cookie-policy`"
                class="text-xs md:text-xsplus text-white"
                >{{ t('COOKIE_POLICY') }}</NuxtLink
              >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/16602365594130-Como-hacer-una-reclamaci%C3%B3n"
                target="_blank"
                class="text-xs md:text-xsplus text-white whitespace-nowrap"
                >{{ t('CLAIMS') }}</NuxtLink
              >
            </div>
          </div>

          <div class="space-y-4 md:space-y-6 order-4 lg:order-3">
            <h4 class="mb-2 font-extrabold tracking-wide md:text-xl">
              {{ t('COMPANY') }}
            </h4>
            <div
              class="flex flex-col gap-y-2 md:gap-y-4 text-xs md:text-xsplus"
            >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/21348492312466-CLUB-SAN-REMO-TIENDAS-FISICAS"
                target="_blank"
                class="text-xs md:text-xsplus text-white whitespace-nowrap"
              >
                {{ t('CLUB') }}
              </NuxtLink>
              <NuxtLink
                :to="`/${locale}/tiendas`"
                class="text-xs md:text-xsplus text-white"
                >{{ t('PHYSICAL_STORES') }}</NuxtLink
              >
              <NuxtLink
                :to="`/${locale}/conocenos`"
                class="text-xs md:text-xsplus text-white"
                >{{ t('KNOW_US') }}</NuxtLink
              >
              <NuxtLink
                to="https://help.perfumeriassanremo.es/hc/es-es/articles/16602405930258-Contacta-con-nosotros"
                target="_blank"
                class="text-xs md:text-xsplus text-white whitespace-nowrap"
              >
                {{ t('CONTACT_US') }}
              </NuxtLink>

              <NuxtLink
                to="mailto:rrhh@sanremo.cat?Subject=Quiero%20trabajar%20con%20vosotros"
                target="_blank"
                class="text-xs md:text-xsplus text-white"
              >
                {{ t('WORK_WITH_US') }}
              </NuxtLink>
            </div>
          </div>

          <div
            class="mt-4 md:mt-6 lg:mt-0 space-y-4 order-3 md:order-4 lg:order-4"
          >
            <h4 class="mb-2 font-extrabold tracking-wide md:text-xl">
              {{ t('WHERE_ARE_WE') }}
            </h4>

            <div class="space-y-4">
              <div
                class="text-xs md:text-xsplus tracking-wide leading-5 md:leading-6 text-white"
              >
                <p>{{ t('ADDRESS') }}</p>
                <p>{{ t('ADDRESS_2') }}</p>
              </div>

              <div
                class="text-xs md:text-xsplus tracking-wide leading-5 md:leading-6 text-white"
              >
                <p class="text-white">{{ t('PHONE') }}</p>
                <NuxtLink
                  to="mailto:help@perfumeriassanremo.es"
                  class="text-blue-500 underline text-xs md:text-xsplus"
                  >help@perfumeriassanremo.es</NuxtLink
                >
                <p class="whitespace-nowrap">{{ t('TIMETABLE') }}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt-10 md:mt-20 lg:mt-12 pl-2 ss:pl-8 md:px-32 lg:px-0 xl:pl-4 xl:pr-2"
        >
          <div
            class="mb-6 md:mb-2 lg:mb-0 flex flex-col items-start lg:flex-row lg:justify-between lg:items-center gap-y-4 lg:gap-y-0"
          >
            <div class="flex justify-start gap-x-2 lg:order-2">
              <a
                href="https://www.instagram.com/perfumeriassanremo/"
                target="_blank"
                rel="noopener noreferer"
              >
                <img
                  src="@/assets/svg/icons/icons-instagram.svg"
                  alt="icono de instagram"
                />
              </a>

              <a
                href="https://www.linkedin.com/in/san-remo-perfumeries-3bb62747/"
                target="_blank"
                rel="noopener noreferer"
              >
                <img
                  src="@/assets/svg/icons/icons-linkedin.svg"
                  alt="icono de linkedin"
                />
              </a>
              <a
                href="https://www.facebook.com/SanRemoPerfumerias"
                target="_blank"
                rel="noopener noreferer"
              >
                <img
                  src="@/assets/svg/icons/icons-facebook.svg"
                  alt="icono de facebook"
                />
              </a>
            </div>

            <div
              class="text-white text-xs font-semibold tracking-wide lg:order-1 ml-2 md:ml-0"
            >
              <p>
                {{ t('COPYRIGHT') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const { locale, t } = useI18n()
const { isMobile } = useDevice()
const expanded = ref(false)
const expand = (item) => {
  if (expanded.value == item) {
    expanded.value = ''
  } else {
    expanded.value = item
  }
}
</script>
