<i18n>
  {
    "es": {
      "ORDER_SUBTOTAL": "Subtotal",
      "ORDER_DISCOUNT": "Descuentos",
      "ORDER_SHIPMENT_FEES": "Envío",
      "ORDER_TOTAL": "Total",
      "DELIVERY": "Se calcula en el Checkout",
      "CURRENCY": "€",
      "FREE": "Gratis"
    },
    "en": {
      "ORDER_SUBTOTAL": "Subtotal",
      "ORDER_DISCOUNT": "Discounts",      
      "ORDER_SHIPMENT_FEES": "Shipping",
      "ORDER_TOTAL": "Total",
      "DELIVERY": "Calculated at Checkout",
      "CURRENCY": "€",
      "FREE": "Free"
    },
    "fr": {
      "ORDER_SUBTOTAL": "Sous-total",
      "ORDER_DISCOUNT": "Remises",
      "ORDER_SHIPMENT_FEES": "Expédition",
      "ORDER_TOTAL": "Total",
      "DELIVERY": "Calculé à la caisse",
      "CURRENCY": "€",
      "FREE": "Gratuit"
    },
    "de": {
      "ORDER_SUBTOTAL": "Zwischensumme",
      "ORDER_DISCOUNT": "Rabatte",
      "ORDER_SHIPMENT_FEES": "Versand",
      "ORDER_TOTAL": "Gesamt",
      "DELIVERY": "Berechnet an der Kasse",
      "CURRENCY": "€",
      "FREE": "Kostenlos"
    },
    "it": {
      "ORDER_SUBTOTAL": "Subtotale",
      "ORDER_DISCOUNT": "Sconti",
      "ORDER_SHIPMENT_FEES": "Spedizione",
      "ORDER_TOTAL": "Totale",
      "DELIVERY": "Calcolato al Checkout",
      "CURRENCY": "€",
      "FREE": "Gratis"
    },
    "pt": {
      "ORDER_SUBTOTAL": "Subtotal",
      "ORDER_DISCOUNT": "Descontos",
      "ORDER_SHIPMENT_FEES": "Envio",
      "ORDER_TOTAL": "Total",
      "DELIVERY": "Calculado no Checkout",
      "CURRENCY": "€",
      "FREE": "Grátis"
    }
  }
</i18n>

<template>
  <div class="px-4">
    <div class="flex flex-col items-start w-full tracking-wider">
      <div class="mb-6 flex items-center w-full justify-between">
        <div v-if="loading" class="w-14 h-4 bg-[#d8d8d8] rounded-lg"></div>
        <span v-else>{{ t('ORDER_SUBTOTAL') }}</span>
        <div v-if="loading" class="w-14 h-4 bg-[#d8d8d8] rounded-lg"></div>
        <span v-else>{{ formatPrice(subtotal) }} {{ currency.symbol }}</span>
      </div>
      <div class="mb-6 flex items-center w-full justify-between">
        <div v-if="loading" class="w-20 h-4 bg-[#d8d8d8] rounded-lg"></div>
        <span v-else> {{ t('ORDER_DISCOUNT') }}</span>
        <div v-if="loading" class="w-14 h-4 bg-[#d8d8d8] rounded-lg"></div>
        <span v-else
          >{{ discount ? '-' : '' }}{{ formatPrice(discount) }}
          {{ currency.symbol }}</span
        >
      </div>
      <div class="mb-2.5 flex items-center w-full justify-between">
        <div v-if="loading" class="flex gap-x-2">
          <div class="w-10 h-4 bg-[#d8d8d8] rounded-lg"></div>
          <div class="w-4 h-4 bg-[#d8d8d8] rounded-lg"></div>
        </div>
        <div v-else class="flex gap-x-2">
          <span>{{ t('ORDER_SHIPMENT_FEES') }}</span>
          <button
            type="button"
            @click="shippingInfoModal = true"
            aria-label="shippingmodal"
          >
            <img src="@/assets/svg/icons/icons-info.svg" class="w-4 h-4" />
          </button>
        </div>
        <div v-if="loading" class="w-14 h-4 bg-[#d8d8d8] rounded-lg"></div>
        <div v-else>
          <span v-if="shipping"
            >{{ formatPrice(shipping) }} {{ currency.symbol }}</span
          >
          <div v-else class="space-x-1">
            <span class="text-xs line-through"
              >{{ mockedShippingCost }}{{ currency.symbol }}</span
            >
            <span class="uppercase text-green-500 font-medium">
              {{ t('FREE') }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <ModalShippingInfo
      :visibility="shippingInfoModal"
      @close="shippingInfoModal = false"
    />
  </div>
</template>

<script setup>
import { formatPrice } from '@/utils/helpers'
const { t } = useI18n({
  useScope: 'local',
})

const props = defineProps({
  totals: {
    type: Object,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
  },
})

const shippingInfoModal = ref(false)

// TODO CHANGE THIS MOCKED COST TO REAL PROPERTY
const mockedShippingCost = ref('4,99')

const subtotal = computed(() => {
  const v = props.totals.find((total) => total.type === 'subtotal')
  return v.value
})

const discount = computed(() => {
  const v = props.totals.find((total) => total.type === 'discount')
  return v.value
})

const shipping = computed(() => {
  const v = props.totals.find((total) => total.type === 'shipping')
  return v.value
})

const total = computed(() => {
  const v = props.totals.find((total) => total.type === 'total')
  return v.value
})
</script>
