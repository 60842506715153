<i18n>
    {
      "es": {
        "INFO": "Más info",
      },
      "en": {
        "INFO": "More info",
      },
      "fr": {
        "INFO": "Plus d'infos",
      },
      "de": {
        "INFO": "Mehr Info",
      },
      "it": {
        "INFO": "Maggiori informazioni",
      },
      "pt": {
        "INFO": "Mais informações",
      },
    }
  </i18n>

<template>
  <div v-if="promotions.length > 0" class="mx-4 mb-4 mt-2 h-24 relative">
    <div
      ref="slider"
      class="active:cursor-grabbing w-full h-full overflow-y-hidden overflow-x-auto scrollbar-hidden rounded-md my-0 mx-auto p-0 snap-mandatory snap-x list-none whitespace-nowrap leading-4 z-10 slide-list relative"
      @scroll="updateSlide"
    >
      <CartPromotionsSliderItem
        v-for="promotion in promotions"
        :key="promotion.id"
        :promotion="promotion"
      />
    </div>

    <div
      id="slide-indicators"
      class="flex justify-center absolute z-10 bottom-4 left-0 right-0"
    >
      <div
        @click="currentSlide = index"
        v-for="(slide, index) in slides"
        :key="index"
        class="slide-indicator h-1 mx-1 cursor-pointer"
        :class="{
          'bg-black w-6': currentSlide === index,
          'bg-gray-300 w-4': currentSlide !== index,
        }"
      ></div>
      <NuxtLink
        :to="props.promotions[currentSlide]?.url"
        v-if="props.promotions[currentSlide]?.url"
        class="underline absolute -bottom-0.5 xs:-bottom-1 right-0 mr-4 text-xxs xs:text-xs"
      >
        {{ t('INFO') }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  promotions: Array,
})

const { t } = useI18n({
  useScope: 'local',
})

const slider = ref()
const currentSlide = ref(0)

const slides = props.promotions?.length

const changingWithScroll = ref(false)
const changingWithClick = ref(false)

const updateSlide = () => {
  if (props.promotions.length == 0) return
  if (changingWithClick.value) return
  changingWithScroll.value = true
  const slideWidth = slider.value.offsetWidth
  const scrollPosition = slider.value.scrollLeft
  const index = Math.round(scrollPosition / slideWidth)
  currentSlide.value = index
  setTimeout(() => {
    changingWithScroll.value = false
  }, 300)
}

watch(
  () => currentSlide.value,
  () => {
    if (props.promotions.length == 0) return
    if (changingWithScroll.value) return
    changingWithClick.value = true
    const slideWidth = slider.value.offsetWidth
    slider.value.scrollTo({
      left: currentSlide.value * slideWidth,
      behavior: 'smooth',
    })
    setTimeout(() => {
      changingWithClick.value = false
    }, 300)
  }
)

const interval = setInterval(() => {
  if (props.promotions.length == 0) return
  if (currentSlide.value < slides - 1) {
    currentSlide.value++
  } else {
    currentSlide.value = 0
  }
}, 5000)

onMounted(() => {
  updateSlide()
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>
