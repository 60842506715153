<template>
  <teleport to="body">
    <div
      class="fixed inset-y-0 right-0 z-[9998] h-screen w-screen transform bg-white text-neutral-black-1 transition duration-200 ease-in-out sm:w-96"
      :class="[visibility ? 'translate-x-0' : 'translate-x-full']"
    >
      <KeepAlive>
        <Cart v-if="cartValue" @close="toggle" />
      </KeepAlive>
    </div>
    <div
      v-if="visibility"
      class="fixed w-full top-0 left-0 bg-others-black bg-opacity-40 h-full z-200"
    ></div>
  </teleport>
</template>

<script setup>
const props = defineProps({
  visibility: {
    type: Boolean,
    required: true,
  },
})

const { hide, cart: cartValue } = useCart()

const toggle = () => {
  hide()
}

watch(cartValue, () => {
  if (cartValue.value) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'auto'
  }
})
</script>
