<template>
  <div
    class="text-xs xs:text-sm px-4 inline-block pt-3 w-full h-full m-0 p-0 snap-center transition-all duration-300"
    :style="`background-color: ${promotion.background_color}`"
  >
    <div class="flex items-center gap-x-1">
      <NuxtImg :src="promotion.icon" class="w-8 h-8" alt="" />

      <div
        class="tracking-wide flex flex-col items-start w-full h-full px-2 leading-4"
      >
        <p class="font-semibold">{{ promotion?.title }}</p>
        <p
          v-if="promotion.subtitle"
          class="mt-1 w-[80%] whitespace-normal text-neutral-grey-1 text-xs"
        >
          {{ subtitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  promotion: Object,
})

const subtitle = ref()

onMounted(() => {
  const updateSubtitleCountdown = () => {
    const countdown = new Date(props.promotion.countdown)
    const now = new Date()

    const countdownValue = countdown - now

    const days = Math.floor(countdownValue / (1000 * 60 * 60 * 24))
    const hours = Math.floor(
      (countdownValue % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    )
    const minutes = Math.floor(
      (countdownValue % (1000 * 60 * 60)) / (1000 * 60)
    )
    const seconds = Math.floor((countdownValue % (1000 * 60)) / 1000)

    const formattedCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s`

    subtitle.value = props.promotion.subtitle.replace(
      '{{ countdown }}',
      formattedCountdown
    )
  }

  if (props.promotion.subtitle) {
    updateSubtitleCountdown()
    setInterval(updateSubtitleCountdown, 1000)
  }
})
</script>
