<template>
  <button
    @click="emit('remove')"
    type="button"
    class="flex items-center space-x-2 border border-sanremo-primaryblue p-2 text-sanremo-primaryblue"
  >
    <span class="whitespace-nowrap text-xs">{{ code }}</span>

    <img src="@/assets/svg/icons/icons-x.svg" class="h-5 w-5" />
  </button>
</template>

<script setup>
const props = defineProps({
  code: {
    type: String,
    default: '',
  },
  enabled: {
    type: Boolean,
  },
})

const emit = defineEmits(['remove'])
</script>
